import { useEffect, useRef } from "react";
import useRouteQuery from "../../hooks/useRouteQuery";

function VerifyAccountPage() {
  const routeQuery = useRouteQuery();
  const ref = useRef<boolean>(false);

  useEffect(() => {
    if (ref.current) return;

    if (routeQuery.get("verify-account")) {
      window.location.replace(
        `peezy://account-verified/${routeQuery.get("verify-account")}`,
      );
    }

    ref.current = true;
  }, [routeQuery]);

  return null;
}

export default VerifyAccountPage;
